<template>
  <b-modal size="lg" v-model="show" :title="modal_title" hide-footer @hide="onHide">
    <el-steps :active="current_step" finish-status="success">
      <el-step title="Partner Data">
        <template slot="icon"><font-awesome-icon icon="building"/></template>
      </el-step>
      <el-step title="PIC Data">
        <template slot="icon"><font-awesome-icon icon="user"/></template>
      </el-step>
      <el-step v-if="modalType === 'add'" title="MyBenefit">
        <template slot="icon"><font-awesome-icon icon="gift"/></template>
      </el-step>
      <el-step v-if="modalType === 'add'" title="Private User">
        <template slot="icon"><font-awesome-icon icon="mobile-alt"/></template>
      </el-step>
      <el-step title="Completed">
        <template slot="icon"><font-awesome-icon icon="check"/></template>
      </el-step>
    </el-steps>
    <div v-loading="loading.create_company">
      <b-form @submit.prevent="onSubmit" @reset="onClose">
        <template v-if="current_step == 0">
          <b-form-group label="Company Name">
            <b-input placeholder="Input new company name" v-model="v$.form1.company_name.$model"/>
            <b-form-invalid-feedback :state="!v$.form1.company_name.$invalid" v-if="v$.form1.company_name.$errors[0]">
              <span> {{ v$.form1.company_name.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Company Email">
            <b-input aria-label="email" placeholder="Input new company email" v-model="v$.form1.company_email.$model"/>
            <b-form-invalid-feedback :state="!v$.form1.company_email.$invalid" v-if="v$.form1.company_email.$errors[0]">
              <span> {{ v$.form1.company_email.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
            <small class="font-weight-bold">Note: This email will be used for invoicing and other related message belong to this company.</small>
          </b-form-group>
          <b-form-group label="Company Phone">
            <b-input aria-label="phone" placeholder="Input new company phone" v-model="v$.form1.company_phone.$model"/>
            <b-form-invalid-feedback :state="!v$.form1.company_phone.$invalid" v-if="v$.form1.company_phone.$errors[0]">
              <span> {{ v$.form1.company_phone.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
            <small class="font-weight-bold">Note: This phone number will be used for wallet activation.</small>
          </b-form-group>
          <b-form-group label="Company Address">
            <el-input type="textarea" aria-label="address" placeholder="Input company address" v-model="v$.form1.address.$model"/>
            <b-form-invalid-feedback :state="!v$.form1.address.$invalid" v-if="v$.form1.address.$errors[0]">
              <span> {{ v$.form1.address.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Company Logo">
            <b-form-file
              @input="onChangeFile($event, 'company_logo')"
              v-model="selected_file.company_logo"
              :state="Boolean(selected_file.company_logo)"
              class="mt-2 border-1 p-2"
              accept=".png, .jpg, .jpeg"
              />
            <div class="row">
              <div class="col-lg-6">
                <img ref="current_company_logo" :src="form1.company_logo_base64"/>
              </div>
            </div>
          </b-form-group>
          <b-form-group label="Company Category Status">
            <el-select size="small" v-model="form2.company_category_status"  placeholder="Select company category status" filterable style="width: 100%">
              <el-option
                v-for="item in company_category_options"
                :key="item.value"
                :label="item.text"
                :value="item.value">
              </el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="CR PAM">
            <b-input placeholder="Input CR PAM" v-model="v$.form1.cr_pam.$model"/>
            <b-form-invalid-feedback :state="!v$.form1.cr_pam.$invalid" v-if="v$.form1.cr_pam.$errors[0]">
              <span> {{ v$.form1.cr_pam.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Location">
            <b-input placeholder="Input location" v-model="v$.form1.location.$model"/>
            <b-form-invalid-feedback :state="!v$.form1.location.$invalid" v-if="v$.form1.location.$errors[0]">
              <span> {{ v$.form1.location.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Latitude">
            <b-input placeholder="Input latitude" v-model="v$.form1.latitude.$model"/>
            <b-form-invalid-feedback :state="!v$.form1.latitude.$invalid" v-if="v$.form1.latitude.$errors[0]">
              <span> {{ v$.form1.latitude.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Longitude">
            <b-input placeholder="Input longitude" v-model="v$.form1.longitude.$model"/>
            <b-form-invalid-feedback :state="!v$.form1.longitude.$invalid" v-if="v$.form1.longitude.$errors[0]">
              <span> {{ v$.form1.longitude.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Industry">
            <b-input placeholder="Input industry" v-model="v$.form1.industry.$model"/>
            <b-form-invalid-feedback :state="!v$.form1.industry.$invalid" v-if="v$.form1.industry.$errors[0]">
              <span> {{ v$.form1.industry.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        <b-form-group label="Province">
          <el-select v-model="v$.form1.province.$model" placeholder="Select province" class="w-full">
            <el-option
              v-for="item in province.rows"
              :key="item.id_province"
              :label="item.province_name"
              :value="item.id_province">
            </el-option>
          </el-select>
          <b-form-invalid-feedback :state="!v$.form1.province.$invalid" v-if="v$.form1.province.$errors[0]">
            <span> {{ v$.form1.province.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
          <b-form-group label="City">
              <el-select v-model="v$.form1.city.$model" placeholder="Select city" class="w-full">
              <el-option
                v-for="item in filteredCities"
                :key="item.id_city"
                :label="item.city_name"
                :value="item.id_city">
              </el-option>
              </el-select>
            <b-form-invalid-feedback :state="!v$.form1.city.$invalid" v-if="v$.form1.city.$errors[0]">
              <span> {{ v$.form1.city.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Company Category">
            <b-input placeholder="Input company category" v-model="v$.form1.company_category.$model"/>
            <b-form-invalid-feedback :state="!v$.form1.company_category.$invalid" v-if="v$.form1.company_category.$errors[0]">
              <span> {{ v$.form1.company_category.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="handlerStep(current_step)" class="mr-2 ml-2" size="small" type="primary">Next</el-button>
            <el-button @click="onClose" size="small" class="mr-2">Cancel</el-button>
          </div>
        </template>
        <template v-if="current_step == 1 && modalType === 'add'">
          <b-form-group label="PIC Name">
            <b-input placeholder="Input new PIC name" v-model="v$.form2.pic_name.$model"/>
            <b-form-invalid-feedback :state="!v$.form2.pic_name.$invalid" v-if="v$.form2.pic_name.$errors[0]">
              <span> {{ v$.form2.pic_name.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="PIC Email">
            <b-input aria-label="email" placeholder="Input new PIC email" v-model="v$.form2.pic_email.$model"/>
            <b-form-invalid-feedback :state="!v$.form2.pic_email.$invalid" v-if="v$.form2.pic_email.$errors[0]">
              <span> {{ v$.form2.pic_email.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
            <small class="font-weight-bold">Note: This email will be used login to B2B Dashboard.</small>
          </b-form-group>
          <b-form-group label="PIC Phone">
            <b-input aria-label="phone" placeholder="Input new PIC phone number" v-model="v$.form2.pic_phone_number.$model"/>
            <b-form-invalid-feedback :state="!v$.form2.pic_phone_number.$invalid" v-if="v$.form2.pic_phone_number.$errors[0]">
              <span> {{ v$.form2.pic_phone_number.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
            <small class="font-weight-bold">Note: This phone number will be used for OTP validation.</small>
          </b-form-group> 
          <b-form-group label="Account Manager (AM)">
            <el-select size="small" v-model="v$.form2.account_manager.$model"  placeholder="Select account manager" filterable style="width: 100%">
              <el-option
                v-for="item in account_manager_options"
                :key="item.value"
                :label="item.text"
                :value="item.value">
              </el-option>
            </el-select>
            <b-form-invalid-feedback :state="!v$.form2.account_manager.$invalid" v-if="v$.form2.account_manager.$errors[0]">
              <span> {{ v$.form2.account_manager.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Last Visit Date">
            <el-date-picker placeholder="Choose last visit date" v-model="form3.last_visit_date"
              :picker-options="datePickerOptions"/>
          </b-form-group>
          <div class="row">
            <div class="col-lg-6">
              <div class="d-flex flex-row float-left">
              <el-button @click="current_step -=1" class="mr-2 ml-2" size="small" type="info">Back</el-button>
            </div>
            </div>
            <div class="col-lg-6">
              <div class="d-flex flex-row float-right">
                <el-button @click="handlerStep(current_step)" class="mr-2 ml-2 float-right" size="small" type="primary">Next</el-button>
                <el-button @click="onClose" size="small" class="mr-2 float-right">Cancel</el-button>
              </div>
            </div>
          </div>
        </template>
        <template v-if="current_step == 1 && modalType === 'edit'">
          <template v-if="selectedCompany.company_internal_users && selectedCompany.company_internal_users.length">
          <b-form-group label="PIC Details">
            <b-table :items="filteredPicCompany" :fields="picFields" small responsive bordered>
              <template #cell(pic_name)="data">
                <b-form-input v-model="data.item.pic_name" placeholder="Input PIC name" />
              </template>
              <template #cell(pic_email)="data">
                <b-form-input v-model="data.item.pic_email" placeholder="Input PIC email" />
              </template>
              <template #cell(pic_phone_number)="data">
                <b-form-input v-model="data.item.pic_phone_number" placeholder="Input PIC phone number" />
              </template>
              <template #cell(actions)="data">
                <b-button size="sm" variant="danger" @click="removePic(data.index)">Delete</b-button>
              </template>
            </b-table>
            <b-button size="sm" varia1nt="primary" @click="addPic">Add PIC</b-button>
          </b-form-group>
          <b-form-group label="Account Manager (AM)">
            <el-select size="small" v-model="v$.form2.account_manager.$model"  placeholder="Select account manager" filterable style="width: 100%">
              <el-option
                v-for="item in account_manager_options"
                :key="item.value"
                :label="item.text"
                :value="item.value">
              </el-option>
            </el-select>
            <b-form-invalid-feedback :state="!v$.form2.account_manager.$invalid" v-if="v$.form2.account_manager.$errors[0]">
              <span> {{ v$.form2.account_manager.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Last Visit Date">
            <el-date-picker placeholder="Choose last visit date" v-model="form2.last_visit_date"/>
            <!-- :picker-options="datePickerOptions" -->
          </b-form-group>
        </template>
          <template v-else>
            <b-form-group label="PIC Name">
              <b-input placeholder="Input new PIC name" v-model="v$.form2.pic_name.$model"/>
              <b-form-invalid-feedback :state="!v$.form2.pic_name.$invalid" v-if="v$.form2.pic_name.$errors[0]">
                <span> {{ v$.form2.pic_name.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="PIC Email">
              <b-input aria-label="email" placeholder="Input new PIC email" v-model="v$.form2.pic_email.$model"/>
              <b-form-invalid-feedback :state="!v$.form2.pic_email.$invalid" v-if="v$.form2.pic_email.$errors[0]">
                <span> {{ v$.form2.pic_email.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
              <small class="font-weight-bold">Note: This email will be used login to B2B Dashboard.</small>
            </b-form-group>
            <b-form-group label="PIC Phone">
              <b-input aria-label="phone" placeholder="Input new PIC phone number" v-model="v$.form2.pic_phone_number.$model"/>
              <b-form-invalid-feedback :state="!v$.form2.pic_phone_number.$invalid" v-if="v$.form2.pic_phone_number.$errors[0]">
                <span> {{ v$.form2.pic_phone_number.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
              <small class="font-weight-bold">Note: This phone number will be used for OTP validation.</small>
            </b-form-group> 
            <b-form-group label="Account Manager (AM)">
              <el-select size="small" v-model="v$.form2.account_manager.$model"  placeholder="Select account manager" filterable style="width: 100%">
                <el-option
                  v-for="item in account_manager_options"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value">
                </el-option>
              </el-select>
            <b-form-invalid-feedback :state="!v$.form2.account_manager.$invalid" v-if="v$.form2.account_manager.$errors[0]">
              <span> {{ v$.form2.account_manager.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Last Visit Date">
            <el-date-picker placeholder="Choose last visit date" v-model="form3.last_visit_date"
              :picker-options="datePickerOptions"/>
          </b-form-group>
          </template>
          <div class="row">
            <div class="col-lg-6">
              <div class="d-flex flex-row float-left">
              <el-button @click="current_step -=1" class="mr-2 ml-2" size="small" type="info">Back</el-button>
            </div>
            </div>
            <div class="col-lg-6">
              <div class="d-flex flex-row float-right">
                <el-button @click="handlerStep(current_step)" class="mr-2 ml-2 float-right" size="small" type="primary">Next</el-button>
                <el-button @click="onClose" size="small" class="mr-2 float-right">Cancel</el-button>
              </div>
            </div>
          </div>
        </template>
        <template v-if="current_step == 2 && modalType === 'add'">
          <p class="mt-2">Choose MyBenefit package for this company. Or you can skip this and set it later.</p>
          <b-form-group label="Package">
            <el-select class="mb-2 mr-2" :loading="loading.package_list" v-model="form3.package_id" placeholder="Select MyBenefit package" style="width: 70%;" @change="handlerSelectPackage">
              <el-option
                v-for="item in package_list"
                :key="item.id"
                :label="item.package_name"
                :value="item.id">
              </el-option>
            </el-select>
            <b-form-invalid-feedback :state="!v$.form3.package_id.$invalid" v-if="v$.form3.package_id.$errors[0]">
              <span> {{ v$.form3.package_id.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Effective Date">
            <el-date-picker placeholder="Choose effective date" v-model="v$.form3.mybenefit_start_at.$model"/>
              <!-- :picker-options="datePickerOptions" -->
            <b-form-invalid-feedback :state="!v$.form3.mybenefit_start_at.$invalid" v-if="v$.form3.mybenefit_start_at.$errors[0]">
              <span> {{ v$.form3.mybenefit_start_at.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Enable EWA Feature">
            <el-switch
              style="display: block"
              v-model="v$.form3.is_enabled_ewa.$model"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Yes"
              inactive-text="No"/>
            <b-form-invalid-feedback :state="!v$.form3.mybenefit_start_at.$invalid" v-if="v$.form3.mybenefit_start_at.$errors[0]">
              <span> {{ v$.form3.mybenefit_start_at.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Enable Reimburse Feature">
            <el-switch
              style="display: block"
              v-model="v$.form3.is_enabled_reimburse.$model"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Yes"
              inactive-text="No"/>
            <b-form-invalid-feedback :state="!v$.form3.mybenefit_start_at.$invalid" v-if="v$.form3.mybenefit_start_at.$errors[0]">
              <span> {{ v$.form3.mybenefit_start_at.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <span v-if="form3.package_id">
            <h4>Package Benefit</h4>
            <b-list-group v-for="(item, index) in benefit_list" :key="index">
              <b-list-group-item><font-awesome-icon :icon="['fas', 'check-circle']" class="text-brand"/>&nbsp;{{ item.benefit_name }}</b-list-group-item>
            </b-list-group>
          </span>
             <div class="row">
            <div class="col-lg-6">
              <div class="d-flex flex-row float-left">
              <el-button @click="current_step -=1" class="mr-2 ml-2" size="small" type="info">Back</el-button>
            </div>
            </div>
            <div class="col-lg-6">
              <div class="d-flex flex-row float-right">
                <el-button @click="handlerStep(current_step)" class="mr-2 ml-2 float-right" size="small" type="primary">Next</el-button>
                <el-button @click="onClose" size="small" class="mr-2 float-right">Cancel</el-button>
              </div>
            </div>
          </div>
        </template>
        <template v-if="current_step == 3">
          <p class="mt-2">Import private user for this company. Or you can skip this process and set it later.</p>
          <b-form-group>
            <b-form-file
              v-model="form4.file"
              :state="Boolean(form4.file)"
              class="border-1 p-2"
              accept=".csv, .xlsx, .xls"
              ref="fileInput"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
            <b-form-invalid-feedback :state="false">
              <div class="w-full flex flex-row justify-content-between">
                <span>*only .xlsx file allowed</span>
                <a :href="require('../../../assets/samples/Sample-Import-User.xlsx')" download><font-awesome-icon icon="download"/> Download example file</a>
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
             <div class="row">
            <div class="col-lg-6">
              <div class="d-flex flex-row float-left">
              <el-button @click="backButtonClickHandler" class="mr-2 ml-2" size="small" type="info">Back</el-button>
            </div>
            </div>
            <div class="col-lg-6">
              <div class="d-flex flex-row float-right">
                <el-button @click="handlerStep(current_step)" class="mr-2 ml-2 float-right" size="small" type="primary">Next</el-button>
                <el-button @click="onClose" size="small" class="mr-2 float-right">Cancel</el-button>
              </div>
            </div>
          </div>
        </template>
        <template v-if="current_step == 4">
          <div class="el-result">
            <div class="el-result__icon">
              <font-awesome-icon icon="check-circle" class="w-5 h-5 text-success"></font-awesome-icon>
            </div>
            <div class="el-result__title">
              <p>Success!</p>
            </div>
            <div class="el-result__subtitle">
              <p>Successfully {{ modalType }} company data.</p>
            </div>
            <div class="el-result__extra">
              <el-button type="primary" @click="onHide" size="small" class="mr-2">Okay</el-button>
            </div>
          </div>
        </template>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import {
  required, email, minLength, numeric, requiredIf, decimal
} from '@vuelidate/validators';
import { CREATE_COMPANIES, UPDATE_COMPANIES, GET_CITY, GET_PROVINCE } from '@/store/modules/companies';
import {
  GET_MYBENEFIT, GET_MYBENEFIT_DETAIL, ASSIGN_PACKAGE,
} from '@/store/modules/mybenefit';
import {
  IMPORT_PRIVATE_USER,
} from '@/store/modules/app_user';
import popupErrorMessages from '@/library/popup-error-messages';
import base64File from '@/library/base64File';

export default {
  name: 'CreateCompany',
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form1: {
        company_name: { required },
        company_phone: {
          required, numeric, minLength: minLength(8),
        },
        company_email: { required, email },
        address: { required },
        cr_pam:{ required, numeric },
        industry:{ required },
        province:{ required },
        city:{ required },
        company_category:{ required },
        latitude:{ required, decimal },
        longitude:{ required, decimal },
        location:{ required },
      },
      form2: {
        pic_name: { required },
        pic_email: { required, email },
        pic_phone_number: { required, numeric },
        account_manager: { required },
      },
      form3: {
        package_id: {
          required: requiredIf(this.form3.mybenefit_start_at),
        },
        mybenefit_start_at: {
          required: requiredIf(this.form3.package_id),
        },
        is_enabled_ewa: {
          boolean: (value) => typeof value === 'boolean',
        },
        is_enabled_reimburse: {
          boolean: (value) => typeof value === 'boolean',
        },
      },
      form4: {},
    };
  },
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: 'add',
    },
    selectedCompany: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      province: [],
      city: [],
      city: {
        rows: [],
      },
      province: {
        rows: [],
      },
      selectedCompany: null,
      savedPicName: '',
      savedPicEmail: '',
      savedPicPhone: '',
      pic_company: [],
      picFields: [
        { key: 'pic_name', label: 'PIC Name' },
        { key: 'pic_email', label: 'PIC Email' },
        { key: 'pic_phone_number', label: 'PIC Phone Number' },
        { key: 'actions', label: 'Actions' },
      ],
      selectedAccountManager: null,
      showNewAccountManagerInput: false,
      newAccountManager: '',
      show: false,
      form1: {
        company_name: '',
        company_email: '',
        company_phone: '',
        address: '',
        logo_file: null,
        company_logo_base64: '',
        cr_pam:'',
        industry:'',
        province:'',
        city:'',
        company_category:'',
        latitude:'',
        longitude:'',
        location:'',
        company_category_status:''
      },
      form2: {
        pic_name: '',
        pic_email: '',
        pic_phone_number: '',
        // full_name: '',
        // email: '',
        // phone_number: '',
        account_manager: '',
        last_visit_date: '',
      },
      form3: {
        package_id: '',
        mybenefit_start_at: '',
        is_enabled_ewa: false,
        is_enabled_reimburse: false,
      },
      form4: {
        file: null,
      },
      selectedCompany: {},
      loading: {
        create_company: false,
        package_list: false,
        benefit_detail: false,
        assign_package: false,
      },
      current_step: 0,
      package_list: [],
      benefit_list: [],
      datePickerOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'),
      },
      selected_file: {
        company_logo: null,
      },
      account_manager_options: [
        { value: 'I Komang Sugiharta', text: 'I Komang Sugiharta' },
        { value: 'Harry Anggriawan',   text: 'Harry Anggriawan' },
      ],
      company_category_options: [
        { value: "non_active", text: 'Non Active' },
        { value: "potencially_active",   text: 'Potencially Active' },
      ],
    };
  },
  computed: {
    modal_title() {
      return this.modalType === 'add' ? 'Create Company' : 'Edit Company';
    },
    backButtonClickHandler() {
      if (this.modalType === 'edit') {
        return () => { this.current_step -= 2; };
      } else {
        return () => { this.current_step -= 1; };
      }
    },
    filteredPicCompany() {
      return this.form2.pic_company.filter(item => !item.hidden);
    },
    filteredCities() {
      return this.city.rows.filter(city => city.province_id === this.v$.form1.province.$model);
    },
  },
  methods: {
    addPic() {
      if (!this.form2.pic_company) {
        this.form2.pic_company = [];
      }
      this.form2.pic_company.push({
        // pic_name: this.form2.pic_name,
        // pic_email: this.form2.pic_email,
        // pic_phone_number: this.form2.pic_phone_number
        pic_name: '',
        pic_email: '',
        pic_phone_number: ''
      });
      this.form2.pic_name = '';
      this.form2.pic_email = '';
      this.form2.pic_phone_number = '';
    },
    removePic(index) {
      this.$set(this.form2.pic_company[index], 'status', 'deleted');
      this.$set(this.form2.pic_company[index], 'hidden', true);
    },
    onHide() {
      this.show = false;
      this.resetForm();
      this.$emit('onHide');
    },
    handlerStep(step) {
      if (this.modalType === 'edit' && step === 1) {
        this.current_step = 3;
        return;
      }
      if (step !== 1) {
        this.v$[`form${step + 1}`].$touch();
        if (this.v$[`form${step + 1}`].$error) {
          return;  
        }
      }
      if (this.modalType === 'edit') {
        if (step === 0) {
          this.current_step = 1;
        } else if (step === 3) {
          this.onSubmit(); 
          this.current_step = 4;
        } else if (step === 4) {
          this.current_step = 3;
        } else {
          this.current_step += 1;
        }
      } else {
        if (step === 3 && this.modalType === 'add') {
          this.onSubmit();
        }
        this.current_step += 1;
      }
    },

    async onSubmit() {
      this.v$.form1.$touch();
      this.v$.form2.$touch();
      this.v$.form3.$touch();
      this.v$.form4.$touch();
      if (this.v$.form1.$error || this.v$.form3.$error || this.v$.form4.$error) return;
      this.form1.cr_pam = parseInt(this.form1.cr_pam);
      this.form1.latitude = parseFloat(this.form1.latitude);
      this.form1.longitude = parseFloat(this.form1.longitude);
      let picCompanyToSend = [];
      if (this.modalType === 'edit') {
        picCompanyToSend = this.form2.pic_company.map(item => {
          if (item.status === 'deleted') {
            return {
              pic_name: item.pic_name,
              pic_email: item.pic_email,
              pic_phone_number: item.pic_phone_number,
              status: 'deleted'
            };
          } else {
            return {
              pic_name: item.pic_name,
              pic_email: item.pic_email,
              pic_phone_number: item.pic_phone_number
            };
          }
        });
      }
      const action = this.modalType === 'add' ? CREATE_COMPANIES : UPDATE_COMPANIES;
      const param = this.modalType === 'add'
        ? { ...this.form1, ...this.form2, ...this.form3 }
        : { 
            id: this.selectedCompany.company_id, 
            data: { 
                ...this.form1, 
                ...this.form2, 
                ...this.form3,
                pic_company: picCompanyToSend, 
                pic_name: this.selectedCompany.pic_name,
                pic_email:this.selectedCompany.pic_email,
                pic_phone_number: this.selectedCompany.pic_phone_number
              } 
          };
    this.loading.create_company = true;
    try {
      const { data } = await this.$store.dispatch(action, param);
    if (this.form3.package_id) {
        await this.$store.dispatch(ASSIGN_PACKAGE, {
          id: this.form3.package_id,
          data: {
            company_id: data.company_id,
            mybenefit_start_at: moment(this.form3.mybenefit_start_at).format('YYYY-MM-DD'),
            is_enabled_ewa: this.form3.is_enabled_ewa,
            is_enabled_reimburse: this.form3.is_enabled_reimburse,
          },
        });
      }
      if (this.form4.file) {
        const body_data = new FormData();
        body_data.append('file', this.form4.file);
        await this.$store.dispatch(IMPORT_PRIVATE_USER, {
          id: data.company_id,
          data: body_data,
        });
      }
      let existDataMessage = '';
      if (this.modalType === 'edit' && data.rows && data.rows.length > 0) {
        existDataMessage = data.rows[0].exist_data
          .map((email, index) => `${index + 1}. ${email}`)
          .join('<br>');
        console.log('Exist data:', existDataMessage);
      }
      this.resetForm();
      this.onClose();
      this.$message({
        title: 'Success',
        type: 'success',
        dangerouslyUseHTMLString: true, 
        message: `Successfully ${this.modalType} company.<br><br>Existing PIC:<br>${existDataMessage}`,
      });
      this.$emit('afterSubmit');
      window.location.reload();
      } catch (err) {
        if (err.response) popupErrorMessages(err.response.data);
      } finally {
      this.loading.create_company = false;
      }
    },
    onClose() {
      this.resetForm();
      this.show = false;
      this.$emit('onClose');
    },
    resetForm() {
      this.current_step = 0;
      this.form1 = {
        company_name: '',
        company_email: '',
        company_phone: '',
        address: '',
        logo_file: null,
        company_logo_base64: '',
        cr_pam:'',
        industry:'',
        province:'',
        city:'',
        company_category:'',
        latitude:'',
        longitude:'',
        location:'',
        company_category_status: ''
      };
      this.form2 = {
        pic_name: '',
        pic_email: '',
        pic_phone_number: '',
        last_visit_date: '',
        account_manager: '',
        full_name: '',
        email: '',
        phone_number: '',
        status: '',
        last_login_at: '',
      };
      this.form3 = {
        package_id: '',
        mybenefit_start_at: '',
        is_enabled_ewa: false,
        is_enabled_reimburse: false,
      };
    },
    async loadMyBenefitList() {
      this.loading.package_list = true;
      await this.$store.dispatch(GET_MYBENEFIT).then((response) => {
        this.package_list = response.rows;
        this.loadBenefitDetail();
      });
      this.loading.package_list = false;
    },
    async loadBenefitDetail() {
      if (this.form3.package_id) {
        this.loading.benefit_detail = true;
        await this.$store.dispatch(GET_MYBENEFIT_DETAIL, {
          id: this.form3.package_id,
        }).then(() => {
          this.benefit_list = this.$store.getters.benefit_list;
        });
        this.loading.benefit_detail = false;
      }
    },
    async getProvince({ search_keyword = '' } = {}) {
      this.loading.company = true;
      await this.$store.dispatch(GET_PROVINCE, {
        search_keyword,
      });
      this.loading.company = false;
      const { rows } = this.$store.getters.province;
      this.province.rows.push(...rows);
      localStorage.setItem('provinceRows', JSON.stringify(this.province.rows));
    },
    async getCity({ search_keyword = '' } = {}) {
      this.loading.company = true;
      await this.$store.dispatch(GET_CITY, {
        search_keyword,
      });
      this.loading.company = false;
      const { rows } = this.$store.getters.city;
      this.city.rows.push(...rows);
      localStorage.setItem('cityRows', JSON.stringify(this.city.rows));
    },
    handlerSelectPackage() {
      this.loadBenefitDetail();
    },
    async onChangeFile(event, target) {
      this.selected_file[`${target}_base64`] = await base64File(this.selected_file[target]);
      try {
        this.$refs[`current_${target}`].src = this.selected_file[`${target}_base64`];
        this.form1[`${target}_base64`] = this.selected_file[`${target}_base64`];
      } catch (error) {
        console.log(error);
      }
    },
    handlerForm1(){
      this.v$.form1.$touch();
        if (this.selectedCompany.company_name) {
          const o = this.selectedCompany;
          const savedProvinceRows = localStorage.getItem('provinceRows');
          const provinceRows = savedProvinceRows ? JSON.parse(savedProvinceRows) : [];
          const savedCityRows = localStorage.getItem('cityRows');
          const cityRows = savedCityRows ? JSON.parse(savedCityRows) : [];
          let selectedProvinceName = '';
          let selectedCityName = '';
          if (!isNaN(Number(o.province))) {
            const selectedProvince = provinceRows.find(
              (item) => Number(item.id_province) === Number(o.province)
            );
            selectedProvinceName = selectedProvince ? selectedProvince.province_name : '';
          } else {
            selectedProvinceName = o.province;
          }
          if (!isNaN(Number(o.city))) {
            const selectedCity = cityRows.find(
              (item) => Number(item.id_city) === Number(o.city)
            );
            selectedCityName = selectedCity ? selectedCity.city_name : '';
          } else {
            selectedCityName = o.city;
          }
          this.form1 = {
            company_name: o.company_name,
            company_email: o.company_email,
            company_phone: o.company_phone,
            address: o.address,
            logo_file: null,
            company_logo_base64: o.company_logo,
            cr_pam: o.cr_pam,
            industry: o.industry,
            province: selectedProvinceName,
            city: selectedCityName,
            company_category: o.company_category,
            latitude: o.latitude,
            longitude: o.longitude,
            location: o.location,
          };
        }
    },
    // handlerForm2(){
    //   // this.v$.form2.$touch();
    //     if (this.selectedCompany) {
    //     const o = this.selectedCompany;
    //     this.form2.pic_company = o.company_internal_users.map(user => ({
    //       pic_name: user.full_name,
    //       pic_email: user.email,
    //       pic_phone_number: user.phone_number,
    //       status: user.status,
    //     }));
    //     const savedPicName = o.pic_name || '';
    //     const savedPicEmail = o.pic_email || '';
    //     const savedPicPhone = o.pic_phone_number || '';
    //     const savedPicStatus = o.status || '';
    //       this.form2 = {
    //         ...this.form2,
    //         pic_name: savedPicName, 
    //         pic_email: savedPicEmail,
    //         pic_phone_number: savedPicPhone,
    //         status: savedPicStatus,
    //         account_manager: o.account_manager || '',
    //         // status: o.status || '',
    //         last_login_at: o.last_login_at || '',
    //         last_visit_date: o.last_visit_date || ''
    //       };
    //     }
    // },
     handlerForm2() {
      this.v$.form2.$touch();
        if (this.selectedCompany && this.selectedCompany.company_name) {
            const o = this.selectedCompany;
          if (Array.isArray(o.company_internal_users) && o.company_internal_users.length > 0) {
            this.form2.pic_company = o.company_internal_users.map(user => ({
              pic_name: user.full_name,
              pic_email: user.email,
              pic_phone_number: user.phone_number,
              status: user.status,
            }));
              this.form2 = {
                ...this.form2,
                pic_name: o.pic_name || '',
                pic_email: o.pic_email || '',
                pic_phone_number: o.pic_phone_number || '',
                status: o.status || '',
                account_manager: o.account_manager || '',
                last_login_at: o.last_login_at || '',
                last_visit_date: o.last_visit_date || ''
              };
          } else {
              this.form2 = {
                  pic_name: o.pic_name || '',
                  pic_email: o.pic_email || '',
                  pic_phone_number: o.pic_phone_number || '',
                  status: o.status || '',
                  account_manager: o.account_manager || '',
                  last_login_at: o.last_login_at || '',
                  last_visit_date: o.last_visit_date || ''
              };
            }
        }
     },
     handlerFormAdd(){
      this.v$.form2.$touch();
      if (this.selectedCompany.company_name) {
      this.form2 = {
        pic_name: o.company_internal_user.full_name,
        pic_email: o.company_internal_user.email,
        pic_phone_number: o.company_internal_user.phone_number,
        account_manager: o.account_manager,
        status: o.company_internal_user.status,
        last_login_at: o.company_internal_user.last_login_at,
        last_visit_date: o.last_visit_date,
        };
      }
    },
    handlerForm3(){
    this.v$.form3.$touch();
      if (this.selectedCompany.company_name) {
        const o = this.selectedCompany;
        this.form3 = {
          mybenefit_package_id: o.mybenefit_package_id,
          mybenefit_package_start_at: o.mybenefit_package_start_at,
          is_enabled_ewa: o.is_enabled_ewa,
          is_enabled_reimburse: o.is_enabled_reimburse,
        };
      }
    },
    handlerForm4(){
    this.v$.form4.$touch();
      if (this.selectedCompany.company_name) {
        const o = this.selectedCompany;
        this.form4 = {
         file: null,
        };
      }
    },
  },
  watch: {
  isShown() {
    this.show = this.isShown;
    if (this.isShown) {
      this.handlerForm1();
       if (this.modalType === 'edit') {
        this.handlerForm2();
      } else if (this.modalType === 'add') {
        this.handlerFormAdd();
      }
      this.handlerForm3();
      this.handlerForm4();
    }
    this.loadMyBenefitList();
    this.getProvince();
    this.getCity();
  },
  selectedCompany(newVal) {
    if (newVal && this.modalType === 'edit') {
      this.handlerForm1();
      this.handlerForm2();
      this.handlerForm3();
      this.handlerForm4();
      this.form2.last_visit_date = newVal.last_visit_date ? moment(newVal.last_visit_date).format('YYYY-MM-DD') : '';
      this.form3.mybenefit_start_at = newVal.mybenefit_start_at ? moment(newVal.mybenefit_start_at).format('YYYY-MM-DD') : '';
    }
  },
},
};
</script>
